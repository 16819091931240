import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Metatags from "../components/metatags"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Navbar from "../components/navbar"
import Footer from "../components/footer"

const NotFoundPage = ({ data }) => (
  <>
    <Metatags
      title="daucodesigns | 404"
      description="detrás de cada producto, hay una historia"
      url="https://daucodesigns.com"
      image="https://daucodesigns.com/logo-daucodesigns.png"
      keywords="muebles, design"
    />
    <Navbar />
    <div className="height40" />
    <div className="height40" />
    <Container>
    <Row>
      <Col className="text-center">
        <h4>Aquí falta algo.</h4>
        <h5>No encontramos la página que estás buscando.</h5>
        <h5>error 404</h5>
      </Col>
    </Row>
      <Row>
        <Col className="text-center">
          <GatsbyImage
            image={getImage(data.img404)}
            backgroundColor={false}
            alt="daucodesigns 404"
            className="img-responsive"
          />
        </Col>
      </Row>
    </Container>
    <div className="height40" />
    <Footer />
  </>
)

export default NotFoundPage

export const query = graphql`
  {
    img404: file(relativePath: { eq: "daucodesigns_404.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
  }
`
